// *********************************************************************************** //
// * Import Styles and Material Design Component Modules                             * //
// *********************************************************************************** //
import 'normalize.css';
import '../styles/index.scss';
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCRipple } from '@material/ripple';
import { MDCList } from '@material/list';
import { MDCDialog } from '@material/dialog';
import { MDCMenuSurface } from '@material/menu-surface';
import { MDCIconButtonToggle } from '@material/icon-button';

const isProduction = process.env.NODE_ENV === 'production';
const serverUrl = isProduction ? '' : 'http://localhost:3000';
const signInPageUrl = `${serverUrl}/auth/sign-in`;

const freeAppUrl = isProduction ? '/app' : 'http://localhost:3000/app';


// *********************************************************************************** //
// * Initialize All Buttons as Material Design Components                            * //
// *********************************************************************************** //
const buttons = document.querySelectorAll('.mdc-button');
buttons.forEach((btn) => {
  // Iterate through all buttons and attach to MDCRipple class
  const rippleButton = new MDCRipple(btn);
  // You can add event listeners on these as well
  rippleButton.listen('click', (event) => {
    // If the button contains data about a Premium Plan
    if (event.target.offsetParent.dataset.plan) {
      // console.log(`You have chosen the ${event.target.offsetParent.dataset.plan} plan`);
      if (isProduction) {
        // GA Event: When a user clicks the "Get Started Now" buttons on the Sales Pricing Table
        window.gtag('event', 'begin_checkout', {
          event_category: 'sales_page_pricing_table',
          event_label: event.target.offsetParent.dataset.plan,
          value: event.target.offsetParent.dataset.price,
        });
      }
    }
    // These are for the list items in the surface menu
    // each list item contains a data-href attribute
    if (event.target.offsetParent.dataset.href) {
      window.location = event.target.offsetParent.dataset.href;
    }
  });
});


// *********************************************************************************** //
// * Set "Join Premium" Buttons to Smooth Scroll to Pricing Table                    * //
// *********************************************************************************** //
const links = document.querySelectorAll('.linkToPricingGrid');

links.forEach((link) => {
  link.addEventListener('click', (event) => {
    event.preventDefault();
    const scrollBehaviorSupported = 'scroll-behavior' in document.documentElement.style;
    if (scrollBehaviorSupported) {
      document.querySelector('#pricingGrid').scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      // Some really old browsers don't support the object passed into scrollIntoView()
      document.querySelector('#pricingGrid').scrollIntoView();
    }
  });
});


// *********************************************************************************** //
// * Top App Bar                                                                     * //
// *********************************************************************************** //
// Get the height of the Top App Bar component and set the padding-top for <main></main>
const topAppBar = new MDCTopAppBar(document.querySelector('.mdc-top-app-bar'));
const topAppBarHeight = topAppBar.root_.getBoundingClientRect().height;
const main = document.querySelector('#main');
main.style.paddingTop = `${topAppBarHeight + 10}px`;

// Hamburger Menu
const menuSurface = new MDCMenuSurface(document.querySelector('.mdc-menu-surface'));
const iconToggle = new MDCIconButtonToggle(document.querySelector('.mdc-icon-button.headerMenuButton'));
iconToggle.listen('click', () => {
  if (iconToggle.on) {
    menuSurface.open(true);
  } else {
    menuSurface.close();
  }
});

menuSurface.listen('MDCMenuSurface:closed', () => {
  iconToggle.on = false;
});

// Hamburger Menu list items
const list = new MDCList(document.querySelector('#menuSurfaceList'));
list.singleSelection = true;
// eslint-disable-next-line array-callback-return
list.listElements.map((listItemEl) => {
  const li = new MDCRipple(listItemEl);
  // eslint-disable-next-line consistent-return
  li.listen('click', () => {
    const scrollBehaviorSupported = 'scroll-behavior' in document.documentElement.style;
    // Bring the user to the blog on the Top App Bar Surface Menu
    if (li.root_.id === 'blog') {
      if (isProduction) {
        window.location.pathname = '/blog';
        return;
      }
      window.location = 'http://localhost:3040/blog';
      return;
    }
    // Bring user to the Quiz app
    if (li.root_.id === 'quiz') {
      if (isProduction) {
        window.location.pathname = '/quiz';
        return;
      }
      window.location = 'http://localhost:3050/quiz';
      return;
    }
    // Log the user in if he clicks on the login button on the surface menu for the Top App Bar
    if (li.root_.id === 'loginButtonOnSurfaceMenu') {
      console.log('Login into the application from Hamburger Surface Menu');
      if (isProduction) {
        // GA Event: Login button on Surface Menu
        window.gtag('event', 'login', {
          event_category: 'sales_page_login',
          event_label: 'surface_menu',
        });
      }
      window.location = signInPageUrl;
      return;
    }
    if (li.root_.id === 'tryFreeAppButtonOnSurfaceMenu') {
      console.log('Try free app from Surface Menu');
      if (isProduction) {
        // GA Event: Try free app from Surface Menu
        window.gtag('event', 'view_item', {
          event_category: 'sales_page_free_app',
          event_label: 'surface_menu',
        });
      }
      window.location = freeAppUrl;
      return;
    }
    // otherwise just scroll to the specific part in the page
    if (scrollBehaviorSupported) {
      document.querySelector(listItemEl.dataset.href).scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      document.querySelector(listItemEl.dataset.href).scrollIntoView();
    }
  });
});

const loginButton = new MDCRipple(document.querySelector('.loginButton'));
loginButton.listen('click', () => {
  console.log('Login into the application from Top App Bar');
  if (isProduction) {
    // GA Event: Login from Top App Bar
    window.gtag('event', 'login', {
      event_category: 'sales_page_login',
      event_label: 'top_app_bar',
    });
  }
  window.location = signInPageUrl;
});

const freeAppButton = new MDCRipple(document.querySelector('.freeAppButton'));
freeAppButton.listen('click', () => {
  console.log(freeAppUrl);
  if (isProduction) {
    // GA Event: Try Free App from the Free vs Premium Plan section
    window.gtag('event', 'view_item', {
      event_category: 'sales_page_free_app',
      event_label: 'free_v_premium_plan',
    });
  }
  window.location = freeAppUrl;
});

const freeAppButtonBelowHeroVideo = new MDCRipple(document.querySelector('.mdc-button.mdc-button--outlined.tff'));
freeAppButtonBelowHeroVideo.listen('click', () => {
  console.log(freeAppUrl);
  if (isProduction) {
    // GA Event: Try Free App from below the Hero Section
    window.gtag('event', 'view_item', {
      event_category: 'sales_page_free_app',
      event_label: 'below_hero_video',
    });
  }
  window.location = freeAppUrl;
});


// *********************************************************************************** //
// * Free vs Premium Plan List                                                       * //
// *********************************************************************************** //
const dialog = new MDCDialog(document.querySelector('.mdc-dialog'));
const dialogTitle = dialog.container_.querySelector('#feature-dialog-title');
const dialogExtraInfo = dialog.container_.querySelector('#feature-dialog-extra-info');
const dialogVideo = dialog.container_.querySelector('.dialogVideo');

const freeList = new MDCList(document.querySelector('.mdc-list.free-list'));
const premiumList = new MDCList(document.querySelector('.mdc-list.premium-list'));
const combinedLists = [freeList, premiumList];

const videoPostersRequireContext = require.context('../img/videoPosters');
const videoRequireContext = require.context('../video');

// Go through each list item and attach a ripple effect
combinedLists.forEach((li) => {
  li.listElements.map((listItemEl) => new MDCRipple(listItemEl));
  li.listen('click', (event) => {
    dialog.content_.childNodes.forEach((node) => {
      // If the iframe video exists, remove it
      if (node.tagName === 'IFRAME') {
        node.remove();
      }
      // Since the iframe video hides the dialogVideo, set the display to unset
      dialogVideo.style.display = 'unset';
    });
    const index = li.getListItemIndex_(event);
    const element = li.foundation_.adapter_.getAttributeForElementIndex(index, 'data-dialog-id');
    if (isProduction) {
      // GA Event: User watches a video from the Free vs Premium Plan section
      window.gtag('event', 'view_item_list', {
        event_category: 'sales_page_video_list',
        event_label: element,
      });
    }
    // When a list item is clicked, match the case to the dataset "data-dialog-id"
    // Once matched, set the content in the dialog menu and open it
    switch (element) {
      // FREE FEATURES
      case 'free_map':
        dialogTitle.innerText = 'Free Plan - Single Map';
        dialogExtraInfo.innerText = 'The Free Plan comes with only 1 map which is provided by Google Maps. Users can view standard maps and satellite maps';
        dialogVideo.src = videoRequireContext('./free_map_prod.mp4');
        dialogVideo.poster = videoPostersRequireContext('./free_map.png');
        dialog.open();
        break;
      case 'free_grid':
        dialogTitle.innerText = 'Free Plan - Basic MGRS Grid Overlay';
        dialogExtraInfo.innerText = 'The Free Plan comes with a simple MGRS overlay for Google Maps. Grid distances cannot be toggled and are based on non-standard intervals based on the users zoom level.';
        dialogVideo.src = videoRequireContext('./free_mgrs_prod.mp4');
        dialogVideo.poster = videoPostersRequireContext('./free_grid.png');
        dialog.open();
        break;
      case 'free_symbols':
        dialogTitle.innerText = 'Free Plan - Limited Symbols';
        dialogExtraInfo.innerText = 'Choose from 24 different ADP 1-02 standard symbols with 7 different affiliations, 15 echelon sizes, 15 symbol modifiers, and 7 different command post types';
        dialogVideo.src = '';
        dialogVideo.poster = videoPostersRequireContext('./free_symbols.png');
        dialog.open();
        break;
      // PREMIUM FEATURES
      case 'premium_map':
        dialogTitle.innerText = 'Premium Plan - Multiple Maps';
        dialogExtraInfo.innerText = 'The Premium Plan allows users to switch between multiple map data providers and map types. There are 5 different maps with 3 types: 2 standard road maps, 1 ink toner map, 1 topographic map, and 1 satellite map. Map data is provided by National Geographic, ArcGIS, Stamen and Open Street Maps';
        dialogVideo.src = videoRequireContext('./premium_maps.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_map.png');
        dialog.open();
        break;
      case 'premium_mgrs':
        dialogTitle.innerText = 'Premium Plan - Enhanced MGRS Grid Overlay';
        dialogExtraInfo.innerText = 'The Premium Plan includes an enhanced MGRS grid overlay for all maps. This overlay is based on standard Grid Zone Designator intervals commonly found on military installation maps using the NAD 83/WGS 84 industry standard. Grid lines and labels can be toggled on or off depending on your preference';
        dialogVideo.src = videoRequireContext('./premium_mgrs.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_mgrs.png');
        dialog.open();
        break;
      case 'premium_symbols':
        dialogTitle.innerText = 'Premium Plan - Map Symbols Updated to Latest Standard';
        dialogExtraInfo.innerText = 'The latest standard in military symbols is ADP 1-02 (Army Doctrine Publication). This publication replaced MIL-STD 2525C in 2015. Premium accounts are given access to this new comprehensive set of icons. ADP 1-02 contains symbols used by all branches of the military. This set includes all Land Forces, Equipment, Activities, Graphic Control Measures, Tactical Mission Tasks, Installations, Supplies, Command Posts, Civilian Authorities, Sea Units, Air Units and Sustainment. MGRS Mapper continually updates the list of symbols as amendments to the current living standard are published.';
        dialogVideo.src = videoRequireContext('./premium_symbols.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_symbols.png');
        dialog.open();
        break;
      case 'premium_symbols_manipulation':
        dialogTitle.innerText = 'Premium Plan - Manipulate Symbols';
        dialogExtraInfo.innerText = 'The Premium Plan allows users to manipulate symbols in many ways that free users cannot. Click on any symbol on the map and drag it to your preferred location. From there you can rotate, resize, invert, scale up or scale the symbol down.';
        dialogVideo.src = videoRequireContext('./premium_symbols_manipulation.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_symbols_manipulation.png');
        dialog.open();
        break;
      case 'premium_symbols_gcm':
        dialogTitle.innerText = 'Premium Plan - Graphic Control Measures';
        dialogExtraInfo.innerText = 'Graphic Control Measures are a symbol used on maps and displays to regulate forces and warfighting functions. Examples of these types of symbols are minefields, key terrain, raft site, bridge, wire obstacle, checkpoint, contact point, ambulance exchange point, waypoint, start point, release point, observation point and many others';
        dialogVideo.src = videoRequireContext('./premium_symbols_gcm.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_symbols_gcm.png');
        dialog.open();
        break;
      case 'premium_symbols_tmt':
        dialogTitle.innerText = 'Premium Plan - Tactical Mission Tasks';
        dialogExtraInfo.innerText = 'Tactical Mission Tasks are the specific activity performed by a unit while executing a form of tactical operation or form of maneuver. It may be expressed in terms of either actions by a friendly force or effects on an enemy force. The Premium Plan allows users to select any Tactical Mission Tasks as defined in ADP 1-02. Tactical Mission Tasks can include Ambush, Abatis, Attack by Fire, Block, Breach, Bypass and many others';
        dialogVideo.src = videoRequireContext('./premium_symbols_tmt.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_symbols_tmt.png');
        dialog.open();
        break;
      case 'premium_symbols_mods':
        dialogTitle.innerText = 'Premium Plan - Symbol Modifiers';
        dialogExtraInfo.innerText = 'ADP 1-02 contains 2 different modifiers for symbols. Modifier 1 (on the top of the symbol) reflects the specific capability of a unit (Armored, Bridging, Sniper, etc..). Modifier 2 (on the bottom of the symbol) reflects the mobility of a unit, or size/range/altitude (tracked, wheeled, Air Assault, Mountain, towed, etc...). In the example video below I am creating a section of friendly Strykers (infantry + armored + wheeled = Stryker)';
        dialogVideo.src = videoRequireContext('./premium_symbols_mods.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_symbols_mods.png');
        dialog.open();
        break;
      case 'premium_map_text':
        dialogTitle.innerText = 'Premium Plan - Add Text to Map';
        dialogExtraInfo.innerText = 'The Premium Plan allows users to switch between multiple map data providers and map types. There are 5 different maps with 3 types: 2 standard road maps, 1 ink toner map, 1 topographic map, and 1 satellite map. Map data is provided by National Geographic, ArcGIS, Stamen and Open Street Maps';
        dialogVideo.src = videoRequireContext('./premium_map_text.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_map_text.png');
        dialog.open();
        break;
      case 'premium_map_draw':
        dialogTitle.innerText = 'Premium Plan - Draw on Map';
        dialogExtraInfo.innerText = 'The Premium Plan allows users to switch between multiple map data providers and map types. There are 5 different maps with 3 types: 2 standard road maps, 1 ink toner map, 1 topographic map, and 1 satellite map. Map data is provided by National Geographic, ArcGIS, Stamen and Open Street Maps';
        dialogVideo.src = videoRequireContext('./premium_map_draw.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_map_draw.png');
        dialog.open();
        break;
      case 'premium_map_screenshot':
        dialogTitle.innerText = 'Premium Plan - One Click Map Screenshot';
        dialogExtraInfo.innerText = 'The Premium Plan allows users to quickly take a screenshot of their map. The file is instantly downloaded to your drive and can be imported in any document or PowerPoint presentation';
        dialogVideo.src = videoRequireContext('./premium_map_screenshot.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_map_screenshot.png');
        dialog.open();
        break;
      case 'premium_map_save':
        dialogTitle.innerText = 'Premium Plan - Save Maps and Symbols';
        dialogExtraInfo.innerText = 'All maps, zoom levels, locations, symbols, markers, lines, drawings, text, and shapes are automatically saved in your local storage for all Premium users. The data is stored on your machine and not on any servers and is only available to you.';
        dialogVideo.src = videoRequireContext('./premium_map_save.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_map_save.png');
        dialog.open();
        break;
      case 'premium_map_search':
        dialogTitle.innerText = 'Premium Plan - Search Locations via Address or Coordinates';
        dialogExtraInfo.innerText = 'The Premium Plan allows users to find locations quickly either by typing in an address or inputting a valid MGRS grid coordinate.';
        dialogVideo.src = videoRequireContext('./premium_map_search.mp4');
        dialogVideo.poster = videoPostersRequireContext('./premium_map_search.png');
        dialog.open();
        break;
      default:
        break;
    }
  });
});

// https://stackoverflow.com/questions/43570460/html5-video-autoplay-on-iphone
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
  get() {
    return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
  },
});

dialog.listen('MDCDialog:opened', () => {
  if (dialogVideo.playing) {
    // Video is already playing so do nothing
  } else {
    // Video is not playing so play video now
    dialogVideo.play();
  }
});

// *********************************************************************************** //
// * Upcoming Features                                                               * //
// *********************************************************************************** //
// This just sets the 'card' height on all the elements in the Upcoming Features divs.
// The 'card' element contains an aqua background color
const cardContainerSize = [];
const cardContainers = document.querySelectorAll('.card > .container');
cardContainers.forEach((c) => {
  const { height } = c.getBoundingClientRect();
  cardContainerSize.push(height);
});

async function setCardContainerSize() {
  const largestSize = await Math.max.apply(null, cardContainerSize);
  cardContainers.forEach((c) => {
    c.setAttribute('style', `height: ${largestSize}px`);
  });
}

setCardContainerSize();


// *********************************************************************************** //
// * Watch Tour - iFrame Video                                                       * //
// *********************************************************************************** //
const watchTourButton = new MDCRipple(document.querySelector('.watchTourButton'));
watchTourButton.listen('click', () => {
  dialogTitle.innerText = 'MGRS Mapper Premium Features';
  dialogExtraInfo.innerText = 'When you upgrade to a Premium account you get access to tons of new features not available in the free version';
  // Set the <video> tag display to none so we can inject an iframe
  dialogVideo.style.display = 'none';
  // Check to see if there is an existing iframe in the dialog container
  // This is useful when a user clicks in and out of the Tour Video, so there won't be multiple iframes
  dialog.content_.childNodes.forEach((node) => {
    if (node.tagName === 'IFRAME') {
      node.remove();
    }
  });
  // Get width of video based on screen width
  const width = document.documentElement.clientWidth < 415 ? 340 : 720;
  const height = document.documentElement.clientWidth < 415 ? width / 1.778 : 405;
  dialogExtraInfo.insertAdjacentHTML('afterend', `<iframe width="${width}px" height="${height}px" src="https://www.youtube.com/embed/UwTo7y_gGNg?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`);
  if (isProduction) {
    // GA Event: User watches the tour video on the Hero Section
    window.gtag('event', 'view_item', {
      event_category: 'sales_page_watch_tour_video',
    });
  }
  dialog.open();
});

// If the dialog window is closed, check if the dialog contains an iframe video
// If it does then remove it, otherwise the audio will play in the background
dialog.listen('MDCDialog:closed', () => {
  dialog.content_.childNodes.forEach((node) => {
    if (node.tagName === 'IFRAME') {
      node.remove();
    }
  });
});


// *********************************************************************************** //
// * Pricing Table                                                                   * //
// *********************************************************************************** //
// Set the button width on the "Get Started Now" buttons to fill the container width
// The click event for these buttons are handled above in the rippleButton loop
const getStartedNowButton = document.querySelectorAll('.getStartedNowButton');
getStartedNowButton.forEach((btn) => {
  const { width } = btn.parentNode.getBoundingClientRect();
  btn.setAttributeNS(null, 'style', `width: ${width}px;`);
});


// *********************************************************************************** //
// * Setting Styles by Device                                                        * //
// *********************************************************************************** //
// If the device orientation is landscape then the Watch Tour button will be white text on white background
// Therefore we will change the background color of that button on page load and orientationchange
// The second if statement detects if the device is an iPad
function readDeviceOrientation() {
  if (Math.abs(window.orientation) === 90 || document.documentElement.clientWidth <= 899) {
    // Landscape
    watchTourButton.root_.style.backgroundColor = 'midnightblue';
    const { width } = document.querySelector('.joinPremiumButton').getBoundingClientRect();
    watchTourButton.root_.style.width = `${width}px`;
    topAppBar.root_.style.paddingBottom = '5px';
    document.querySelectorAll('.mdc-top-app-bar__section.mdc-top-app-bar__section--align-end > button > a > svg').forEach((n) => {
      n.setAttributeNS(null, 'width', 36);
      n.setAttributeNS(null, 'height', 36);
    });
  }
}

window.onload = readDeviceOrientation;
window.onorientationchange = readDeviceOrientation;
window.onresize = readDeviceOrientation;
